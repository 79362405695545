import http from "@/_helper/api-services.js";

export default {
    name: "anuncios",
    data() {
        return {
            categoriaSelecionada: String(),
            items: [],
            fields: [
                { key: "nomeArquivo", label: this.$i18n.t("cursos.nome") },
                { key: "tipo", label: this.$i18n.t("cursos.tipo") },
                { key: "actions", label: "" },
            ],
            urlVideo: String(),
            nomeVideo: String(),
            tipo: String(),
            anuncios: [],
            uploadAnuncio: {
                titulo: String(),
                descricao: String(),
                urlVideo: String(),
                file: null
            },
            myVideos: []
        };
    },
    beforeMount() {
        this.buscarAnuncios();
    },
    methods: {
        buscarAnuncios() {
            this.$loading(true);
            http.get("/adverts/advertsToWatch").then((response) => {
                if (response.status == 200)
                    this.anuncios = response.data;

                this.$loading(false);
            }).catch((error) => {
                this.$snotify.error(error.response.data);
            });
        },
        removeSpecialCharacters(text) {
            return text.replace(/ /g, "").replace(/[^\w\s]/gi, "");
        },
        getCursos() {
            this.$loading(true);
            http
                .get("/product/listFilesProductActive")
                .then(
                    (success) => {
                        if (success.data) {
                            this.items = success.data.reduce((r, a) => {
                                r[a.nomeCategoria] = [...(r[a.nomeCategoria] || []), a];
                                return r;
                            }, {});
                        }
                    },
                    (error) => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        openModal(nomeVideo, video, tipo) {
            this.nomeVideo = nomeVideo;
            this.urlVideo = video;
            this.tipo = tipo;
            this.$bvModal.show("modal-videos");
        },
        uploadArquivo() {
            let teste = this.uploadAnuncio.file;
            this.myVideos = [];
            var video = document.createElement('video');
            video.preload = 'metadata';
            this.myVideos.push(teste);

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                var duration = video.duration;
                this.myVideos[0].duration = duration;
                this.verificaTamanho();
            }

            video.src = URL.createObjectURL(teste);;

            // for (let i = 0; i < myVideos.length; i++) {
            //     alert(myVideos[i].duration);
            // }
        },
        verificaTamanho() {
            alert('caiu auqi');
        }
    }
};
